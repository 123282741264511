@import '~@ui/styles/tools';
@import 'src/styles/variables';

.range-container {
    margin-bottom: 32px;

    & > * {
        &:last-child {
            margin-bottom: 5px;
        }
        & > *:first-child {
            background-color: transparent;
            padding: 20px 0 20px;
            height: 56px;
        }
    }

    &:last-of-type {
        margin-bottom: 56px;
    }
}

.result-container {
    margin: -51px 0 -32px;
}

.calculator-label {
    font-size: 14px;
    font-weight: 400;
    color: rgba(30, 32, 33, 0.5);
    white-space: nowrap;

    &.calculator-label--large {
        font-size: 16px;
        line-height: normal;
    }
}

.range-label {
    font-size: 20px;
    font-weight: 400;
    color: $color-title-active;
}

.range-line {
    background: #d9d9d9;
}

.result {
    padding-top: 56px;
    padding-bottom: 32px;

    & > *:first-child {
        margin-bottom: 7px;
    }
}

.result-value-wrapper {
    min-width: 150px;
    font-size: 44px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0px;
    color: rgba(32, 131, 109, 1);
}

.result-currency {
    color: rgba(32, 131, 109, 0.4);
}

.result-value {
    white-space: nowrap;
}

@include media-tablet {
    .result-value-wrapper {
        font-size: 32px;
        line-height: 130%;
    }
}

@include media-mobile {
    .range-container {
        margin-bottom: 28px;
    }

    .range-container > *:last-child {
        padding: 0;
    }

    .result-container {
        margin: 0;
    }

    .calculator-label {
        margin-bottom: 10px;
    }

    .calculator-label,
    .calculator-label.calculator-label--large {
        font-size: 10px;
    }

    .range-label {
        font-size: $font-size--normal;
    }

    .result {
        padding: 0 !important;
    }
}
