@import '~@ui/styles/tools';
@import 'src/styles/variables';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

$color-description: #64696c;

@mixin feature-heading {
    color: #1c2723;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;

    @include media-tablet {
        font-size: 20px;
    }
}

@mixin feature-description {
    font-size: 20px;
    line-height: normal;
    color: $color-text-light;
    margin-bottom: 0;

    @include media-tablet {
        font-size: 16px;
    }
}

.feature-title {
    font-size: 48px;
    font-weight: 600;
    max-width: 18ch;
    margin-bottom: 32px;
}

.feature__slider {
    display: none;
}

.feature__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    & > *:not(:last-child) {
        margin-right: 32px;
    }
}

.card {
    height: 100%;
    max-height: 550px;
    background-color: #ffffff;
    border-radius: 24px;
    padding: 32px;
    display: grid;
    grid-template-rows: max-content max-content 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: 'heading' 'description' 'image';

    overflow: hidden;

    box-sizing: border-box;
}

.card__title-container {
    grid-area: heading;
    margin-bottom: 16px;
}

.card__description-container {
    grid-area: description;
}

.card__image-container {
    grid-area: image;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    height: 100%;
    margin-top: auto;
}

.card__title {
    @include feature-heading;
}

.card__description {
    @include feature-description;
}

.card__image {
    position: absolute;
    left: 50px;
    right: 50px;
    top: 0;
    bottom: -32px;
    pointer-events: none;

    img {
        width: unset !important;
        left: 50% !important;
        transform: translateX(-50%);
        pointer-events: none;
    }
}

.card.card--first {
    .card__title {
        max-width: 15ch;
    }
    .card__description {
        max-width: 30ch;
    }

    .card__image-container {
        min-height: 328px;
        max-height: 328px;
    }

    .card__image {
        margin-top: auto;
        max-height: 360px;
        height: 100%;
        left: 0;
        right: 0;
    }
}

.card.card--second {
    .card__title {
        max-width: 15ch;
    }
    .card__description {
        max-width: 22ch;
    }

    .card__image-container {
        max-height: 291px;
        min-height: 291px;
    }

    .card__image {
        margin-top: auto;
        max-height: 323px;
        height: 100%;
        min-height: unset;
        left: 0;
        right: 0;
    }
}

.card.card--third {
    .card__title {
        max-width: 15ch;
    }
    .card__description {
        max-width: 25ch;
    }

    .card__image-container {
        max-height: 251px;
        min-height: 251px;
    }

    .card__image {
        max-height: 283px;
        height: 100%;
        min-height: unset;
        margin-top: auto;
        left: -32px;
        right: -32px;
        img {
            width: 100% !important;
        }
    }
}

@include medium-breakpoint {
    .feature-title {
        font-size: 40px;
    }
}

@include small-breakpoint {
    .feature-title {
        font-size: 32px;
    }

    .feature__cards {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, max-content);
        row-gap: 32px;
        & > *:not(:last-child) {
            margin-right: 0;
            margin-bottom: 32px;
        }
    }

    .card {
        grid-template-columns: max-content 1fr;
        grid-template-rows: min-content 1fr;
        grid-template-areas:
            'heading image'
            'description image';

        &.card--first,
        &.card--second,
        &.card--third {
            .card__image-container {
                max-height: 200px;
                min-height: 200px;

                width: 350px;
                margin-left: auto;
            }

            .card__image {
                img {
                    left: 50% !important;
                    transform: translateX(-50%);
                }
            }
        }

        &.card--third {
            .card__image {
                left: unset;
                right: unset;

                img {
                    width: auto !important;
                }
            }
        }
    }

    .card__image {
        position: relative;
        width: 100%;

        img {
            top: 32px !important;
            // left: 100% !important;
            // transform: translateX(-100%);
            width: auto !important;
        }
    }
}

@include media-tablet {
    .feature-title {
        font-size: 24px;
    }

    .feature__slider {
        display: block;
    }

    .feature__cards {
        display: none;
        margin-bottom: 0;
    }

    .card {
        width: 100%;
        height: 300px;
        padding: 20px;

        grid-template-rows: max-content max-content 1fr;
        grid-template-columns: 1fr;
        grid-template-areas: 'heading' 'description' 'image';

        &.card--first,
        &.card--second,
        &.card--third {
            .card__image-container {
                min-height: unset;
                max-height: unset;
                width: 100%;
                margin-left: unset;
            }

            .card__image {
                max-height: unset;
                height: calc(100% + 20px);

                img {
                    top: 0 !important;
                    bottom: 0 !important;
                    left: 50% !important;
                    transform: translateX(-50%);
                }
            }
        }

        &.card--third {
            .card__image {
                width: calc(100% + 20px * 2);
                height: 100%;
                img {
                    width: 100% !important;
                    top: 20px !important;
                    bottom: -20px !important;
                }
            }
        }
    }
}

@include media-mobile {
    .feature-title {
        font-size: 26px;
        max-width: 15ch;
    }
}
